import { useContext, useEffect } from "react";
import { auth, dataBase } from "../firebaseConnection";
import { signOut } from "firebase/auth";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, deleteObject, ref } from "firebase/storage";
import { HouseContext } from "../components/HouseContext";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PlusCircle, LogOut, Trash2, Pencil } from "lucide-react"
import House from "../components/House";
import { supabase } from "../supabaseClient";
import { useNavigate } from 'react-router-dom';

const handleLogout = async () => {
  await signOut(auth);
};

const deleteOldImage = async (url) => {
  try {
    const supabaseUrl = supabase.storage.from("properties").getPublicUrl('').data.publicUrl;
    
    const path = url.replace(supabaseUrl, '');

    const { error } = await supabase.storage
      .from("properties")
      .remove([path]);
    
    if (error) throw error;
  } catch (error) {
    console.error('Erro ao deletar imagem:', error.message);
  }
};

async function deleteProperty(id, setAllProperties) {
  const docRef = doc(dataBase, "properties", id);
  
  try {
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return alert("Anúncio não encontrado!");
    const property = docSnap.data();

    if (property.mainPicture) await deleteOldImage(property.mainPicture);
    
    if (property.carouselPictures) {
      for (let pic of property.carouselPictures) {
        await deleteOldImage(pic);
      }
    }

    if (property.agentPicture) await deleteOldImage(property.agentPicture);

    await deleteDoc(docRef);

    // Atualizar estado
    setAllProperties(prev => prev.filter(p => p.id !== id));
    toast.success("Anúncio removido com sucesso!");
  } catch (error) {
    toast.error("Falha ao deletar anúncio.")
    console.error("Erro crítico:", error);
  }
}

const Admin = () => {
  const { allProperties, setAllProperties } = useContext(HouseContext);
  const navigate = useNavigate();

  return (
    <div className="min-h-screen py-10">
      {window.scrollTo(0, 0)}

      {/* Botões de ação */}
      <div className="fixed top-5 right-5 flex gap-4">
        <Link
          to="/admin/newproperty"
          className="flex items-center gap-2 bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-lg shadow-md transition-all"
        >
          <PlusCircle size={20} />
          Adicionar Imóvel
        </Link>

        <button
          onClick={handleLogout}
          className="flex items-center gap-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg shadow-md transition-all"
        >
          <LogOut size={20} />
          Sair
        </button>
      </div>

      {/* Título */}
      <h1 className="text-white text-4xl font-semibold text-center mb-8">
        Lista de Propriedades
      </h1>

      {/* Lista de propriedades */}
      <div className="container mx-auto px-4">
        {allProperties.length === 0 ? (
          <p className="text-center text-gray-300 text-lg">
            Nenhuma propriedade cadastrada.
          </p>
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {allProperties.map((house) => (
              <div key={house.id} className="bg-gray-800 p-4 rounded-lg shadow-lg">
                <House house={house} />
                <button
                  onClick={() => deleteProperty(house.id, setAllProperties)}
                  className="mt-4 flex items-center gap-2 bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-2 rounded-lg w-full justify-center transition-all"
                >
                  <Trash2 size={18} />
                  Apagar Anúncio
                </button>
                <Link
                  to={`edit/${house.id}`}
                  className="mt-4 flex items-center gap-2 bg-blue-800 hover:bg-blue-900 text-white px-3 py-2 rounded-lg w-full justify-center transition-all"
                >
                  <Pencil size={18} />
                  Editar Anúncio
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
