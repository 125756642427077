import React, { useCallback, useEffect, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import PropTypes from "prop-types";

const Carousel = ({ images, className }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setCurrentIndex(emblaApi.selectedScrollSnap());
    };

    emblaApi.on("select", onSelect);
    onSelect();
  }, [emblaApi]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  return (
    <div className={`relative w-full overflow-hidden group ${className}`}>
      {/* Carrossel Principal */}
      <div className="embla" ref={emblaRef}>
        <div className="flex">
          {images.map((image, index) => (
            <div className="flex-shrink-0 w-full" key={index}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                className="w-full h-96 object-cover rounded-lg shadow-lg"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Botões de Navegação */}
      <button
        className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 rounded-full bg-white bg-opacity-50 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100"
        aria-label="Previous slide"
        onClick={scrollPrev}
      >
        <RiArrowLeftSLine size={24} />
      </button>
      <button
        className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 rounded-full bg-white bg-opacity-50 hover:bg-opacity-75 transition-opacity opacity-0 group-hover:opacity-100"
        aria-label="Next slide"
        onClick={scrollNext}
      >
        <RiArrowRightSLine size={24} />
      </button>

      {/* Indicadores de Slide */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full transition-colors ${
              index === currentIndex ? "bg-white" : "bg-white bg-opacity-50"
            }`}
            aria-label={`Go to slide ${index + 1}`}
            onClick={() => emblaApi && emblaApi.scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Carousel;