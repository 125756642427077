import { Upload } from "lucide-react";
import Carousel from "../components/Carousel";

const FileUploadInput = ({ label, multiple, onChange, file, preview = [] }) => (
  <div>
    <label className="block text-sm font-medium text-gray-300 mb-2">
      {label}
    </label>
    <label className="flex flex-col items-center justify-center w-full h-24 border-2 border-dashed border-gray-600 rounded-lg cursor-pointer hover:border-blue-500 transition-colors bg-gray-700 relative"> {/* Altura reduzida */}
      <div className="flex flex-col items-center text-gray-400">
        <Upload className="w-5 h-5 mb-1" /> {/* Icone menor */}
        <span className="text-xs">Enviar</span> {/* Texto menor */}
        <span className="text-[0.6rem] text-gray-500">PNG, JPG, JPEG (max. 5MB)</span> {/* Texto menor ainda */}
      </div>

      {/* Feedback visual dos arquivos selecionados */}
      {file && (
        <div className="absolute bottom-1 left-1 text-[0.6rem] text-gray-400"> {/* Texto menor */}
          {multiple ? `${file.length} arquivo(s)` : file.name}
        </div>
      )}

      <input
        type="file"
        className="hidden"
        multiple={multiple}
        onChange={(e) => {
          if (multiple) {
            onChange(Array.from(e.target.files)); // Converte FileList para array
          } else {
            onChange(e.target.files[0]);
          }
        }}
        accept="image/*"
      />
    </label>
    <div className="mt-2"> {/* Espaço menor acima do carrossel */}
      {Array.isArray(preview) && preview.length > 1 ? (
        <Carousel className="h-64" images={preview} />
      ) : (
        <img src={preview} className="w-64 h-64 object-cover rounded-md" /> 
      )}
    </div>
  </div>
);

export default FileUploadInput;