import { useContext, useState, useEffect } from "react";
import InputField from "../components/InputField";
import SelectField from "../components/SelectField";
import FileUploadInput from "../components/FileUploadInput";
import { Upload, Loader2, MoveLeft } from "lucide-react";
import { Link } from "react-router-dom";
import { auth, dataBase } from "../firebaseConnection";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { supabase } from "../supabaseClient";
import { useParams, useNavigate } from "react-router-dom";
import { HouseContext } from "../components/HouseContext";
import { toast } from "react-toastify";

const EditHouse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { allProperties, setAllProperties } = useContext(HouseContext);

  const options = ["Apartamento", "Casa", "Terreno"];
  const [loading, setLoading] = useState(false);

  // Estados para cada campo do formulário
  const [newPropertyName, setNewPropertyName] = useState("");
  const [newPropertyType, setNewPropertyType] = useState(options[0]);
  const [newPropertyDescription, setNewPropertyDescription] = useState("");
  const [newPropertyAddress, setNewPropertyAddress] = useState("");
  const [newPropertyNeighborhood, setNewPropertyNeighborhood] = useState("");
  const [newPropertyLatitude, setNewPropertyLatitude] = useState("");
  const [newPropertyLongitude, setNewPropertyLongitude] = useState("");
  const [newPropertyBathrooms, setNewPropertyBathrooms] = useState(0);
  const [newPropertySuites, setNewPropertySuites] = useState(0);
  const [newPropertyBedrooms, setNewPropertyBedrooms] = useState(0);
  const [newPropertySurface, setNewPropertySurface] = useState("");
  const [newPropertyPrice, setNewPropertyPrice] = useState("");
  const [newAgentName, setNewAgentName] = useState("");
  const [newAgentNumber, setNewAgentNumber] = useState("");

  // Estados para as imagens
  const [existingImages, setExistingImages] = useState({
    main: "",
    carousel: [],
    agent: "",
  });

  const [carouselFileToBeUploaded, setCarouselFileToBeUploaded] = useState([]);
  const [mainFileToBeUploaded, setMainFileToBeUploaded] = useState(null);
  const [agentFileToBeUploaded, setAgentFileToBeUploaded] = useState(null);

  // Buscar dados existentes
  useEffect(() => {
    const fetchPropertyData = async () => {
      setLoading(true);
      try {
        const docRef = doc(dataBase, "properties", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Preencher todos os estados com os dados existentes
          setNewPropertyName(data.propertyName);
          setNewPropertyType(data.propertyType);
          setNewPropertyDescription(data.propertyDescription);
          setNewPropertyAddress(data.propertyAdress);
          setNewPropertyNeighborhood(data.propertyNeighborhood);
          setNewPropertyLatitude(data.propertyLatitude);
          setNewPropertyLongitude(data.propertyLongitude);
          setNewPropertyBathrooms(data.propertyBathrooms);
          setNewPropertySuites(data.propertySuites);
          setNewPropertyBedrooms(data.propertyBedrooms);
          setNewPropertySurface(data.propertySurface);
          setNewPropertyPrice(data.propertyPrice);
          setNewAgentName(data.agentName);
          setNewAgentNumber(data.agentNumber);
          setExistingImages({
            main: data.mainPicture,
            carousel: data.carouselPictures,
            agent: data.agentPicture,
          });
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [id]);

  const uploadCarouselImages = async (files) => {
    const urls = [];
    for (const file of files) {
      const fileName = `${Date.now()}-${file.name}`;
      const { data: uploadData, error } = await supabase.storage
        .from("properties")
        .upload(`carousel/${fileName}`, file);

      if (error) {
        console.error("Erro:", error);
        continue;
      }

      // Correção aqui ↓
      const { data: urlData } = supabase.storage
        .from("properties")
        .getPublicUrl(uploadData.path);

      urls.push(urlData.publicUrl); // Note o "l" minúsculo
    }
    return urls;
  };

  const uploadMainImage = async (file) => {
    try {
      const fileName = `${Date.now()}-${file.name}`;
      const { data: uploadData, error } = await supabase.storage
        .from("properties")
        .upload(`main/${fileName}`, file);

      if (error) throw error;

      // Correção aqui ↓
      const { data: urlData } = supabase.storage
        .from("properties")
        .getPublicUrl(uploadData.path);

      return urlData.publicUrl; // Note o "l" minúsculo
    } catch (error) {
      console.error(`Erro: ${error.message}`);
      return null;
    }
  };

  const uploadAgentImage = async (file) => {
    try {
      const fileName = `${Date.now()}-${file.name}`;
      const { data: uploadData, error } = await supabase.storage
        .from("properties")
        .upload(`agent/${fileName}`, file);

      if (error) throw error;

      const { data: urlData } = supabase.storage
        .from("properties")
        .getPublicUrl(uploadData.path);

      return urlData.publicUrl;
    } catch (error) {
      console.error(`Erro na imagem principal: ${error.message}`);
      return null;
    }
  };

  const deleteOldImage = async (url) => {
    try {
      const supabaseUrl = supabase.storage.from("properties").getPublicUrl('').data.publicUrl;
      
      const path = url.replace(supabaseUrl, '');

      const { error } = await supabase.storage
        .from("properties")
        .remove([path]);
      
      if (error) throw error;
    } catch (error) {
      console.error('Erro ao deletar imagem:', error.message);
    }
  };

  // Handler de submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const newImages = {
        main: existingImages.main,
        carousel: [...existingImages.carousel],
        agent: existingImages.agent,
      };

      if (mainFileToBeUploaded) {
        await deleteOldImage(existingImages.main);
        const url = await uploadMainImage(mainFileToBeUploaded);
        newImages.main = url;
      }

      if (carouselFileToBeUploaded.length > 0) {
        await Promise.all(
          existingImages.carousel.map((img) => deleteOldImage(img))
        );
        const urls = await uploadCarouselImages(carouselFileToBeUploaded);
        newImages.carousel = urls;
      }

      if (agentFileToBeUploaded) {
        await deleteOldImage(existingImages.agent);
        const url = await uploadAgentImage(agentFileToBeUploaded);
        newImages.agent = url;
      }


      // Atualizar documento no Firestore
      const updatedData = {
        userId: auth?.currentUser?.uid,
        propertyName: newPropertyName,
        propertyType: newPropertyType,
        propertyDescription: newPropertyDescription,
        propertyNeighborhood: newPropertyNeighborhood,
        propertyAdress: newPropertyAddress,
        propertyLatitude: newPropertyLatitude,
        propertyLongitude: newPropertyLongitude,
        propertyBathrooms: newPropertyBathrooms,
        propertySuites: newPropertySuites,
        propertyBedrooms: newPropertyBedrooms,
        propertySurface: newPropertySurface,
        propertyPrice: newPropertyPrice,
        agentName: newAgentName,
        agentNumber: newAgentNumber,
        mainPicture: newImages.main,
        carouselPictures: newImages.carousel,
        agentPicture: newImages.agent,
      };

      await updateDoc(doc(dataBase, "properties", id), updatedData);
      // Atualizar contexto
      setAllProperties((prev) =>
        prev.map((prop) =>
          prop.id === id ? { ...prop, ...updatedData } : prop
        )
      );
      toast.success("Propriedade editada com sucesso.")
      navigate("/admin");
    } catch (error) {
      toast.error("Erro ao atualizar:", error)
      console.error("Erro ao atualizar:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen py-8 px-4 mb-14">
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Loader2 className="animate-spin h-12 w-12 text-blue-500" />
        </div>
      )}

      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-white">Edição Imóvel</h1>
          <Link
            to="/admin"
            className="flex items-center gap-2 px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg transition-colors"
          >
            <MoveLeft className="w-5 h-5" />
            Voltar
          </Link>
        </div>

        <form
          onSubmit={handleSubmit}
          className="space-y-6 bg-gray-800 p-6 rounded-xl drop-shadow-lg"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Seção Informações Básicas */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Informações Básicas
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  label="Nome da Propriedade*"
                  value={newPropertyName}
                  onChange={setNewPropertyName}
                  placeholder="Ex: Residencial Green Valley"
                />
                <SelectField
                  label="Tipo de Imóvel*"
                  value={newPropertyType}
                  onChange={setNewPropertyType}
                  options={options}
                />
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-300 mb-2">
                    Descrição*
                  </label>
                  <textarea
                    rows={4}
                    value={newPropertyDescription}
                    onChange={(e) => setNewPropertyDescription(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Descreva detalhes importantes do imóvel..."
                  />
                </div>
              </div>
            </div>

            {/* Seção Localização */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Localização
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  label="Endereço*"
                  value={newPropertyAddress}
                  onChange={setNewPropertyAddress}
                  placeholder="Rua, número"
                />
                <InputField
                  label="Bairro*"
                  value={newPropertyNeighborhood}
                  onChange={setNewPropertyNeighborhood}
                  placeholder="Nome do bairro"
                />
                <InputField
                  label="Latitude*"
                  type="number"
                  value={newPropertyLatitude}
                  onChange={setNewPropertyLatitude}
                  placeholder="Ex: -23.550650"
                />
                <InputField
                  label="Longitude*"
                  type="number"
                  value={newPropertyLongitude}
                  onChange={setNewPropertyLongitude}
                  placeholder="Ex: -46.633382"
                />
                <div className="text-gray-600 text-sm mb-2">
                  <strong>DICA:</strong> Entre no{" "}
                  <a
                    href="https://www.google.com/maps"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline hover:text-blue-700"
                  >
                    Google Maps
                  </a>{" "}
                  para pegar latitude e longitude.
                </div>
              </div>
            </div>

            {/* Seção Detalhes da Propriedade */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Detalhes da Propriedade
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <SelectField
                  label="Banheiros*"
                  value={newPropertyBathrooms}
                  onChange={setNewPropertyBathrooms}
                  options={Array.from({ length: 11 }, (_, i) => i)}
                />
                <SelectField
                  label="Suítes*"
                  value={newPropertySuites}
                  onChange={setNewPropertySuites}
                  options={Array.from({ length: 11 }, (_, i) => i)}
                />
                <SelectField
                  label="Quartos*"
                  value={newPropertyBedrooms}
                  onChange={setNewPropertyBedrooms}
                  options={Array.from({ length: 11 }, (_, i) => i)}
                />
                <InputField
                  label="Área (m²)*"
                  type="number"
                  value={newPropertySurface}
                  onChange={setNewPropertySurface}
                  placeholder="Ex: 150"
                />
                <InputField
                  label="Valor (R$)*"
                  type="number"
                  value={newPropertyPrice}
                  onChange={setNewPropertyPrice}
                  placeholder="Ex: 500000"
                />
              </div>
            </div>

            {/* Seção Imagens */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Imagens
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <FileUploadInput
                  label="Imagem Principal*"
                  file={mainFileToBeUploaded}
                  onChange={(files) => setMainFileToBeUploaded(files)}
                  preview={existingImages.main}
                />

                <FileUploadInput
                  label="Fotos do Carrossel*"
                  multiple
                  file={carouselFileToBeUploaded}
                  onChange={(files) => setCarouselFileToBeUploaded(files)}
                  preview={existingImages.carousel}
                />

                <FileUploadInput
                  label="Foto do Corretor*"
                  file={agentFileToBeUploaded}
                  onChange={(files) => setAgentFileToBeUploaded(files)}
                  preview={existingImages.agent}
                />
              </div>
            </div>

            {/* Seção Dados do Corretor */}
            <div className="col-span-2">
              <h2 className="text-xl font-semibold text-white mb-4 border-b border-gray-700 pb-2">
                Dados do Corretor
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputField
                  label="Nome do Corretor*"
                  value={newAgentName}
                  onChange={setNewAgentName}
                  placeholder="Nome completo"
                />
                <InputField
                  label="Telefone*"
                  value={newAgentNumber}
                  onChange={(e) => {
                    setNewAgentNumber(e.target.value);
                  }}
                  placeholder="(11) 99999-9999"
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors flex items-center justify-center gap-2"
            disabled={loading}
          >
            {loading ? (
              <Loader2 className="animate-spin h-5 w-5" />
            ) : (
              <>
                <Upload className="w-5 h-5" />
                Atualizar Imóvel
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditHouse;
