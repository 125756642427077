import React, { useState, useEffect, createContext } from "react";
import { dataBase } from "../firebaseConnection";
import { query, collection, onSnapshot } from "firebase/firestore";

export const HouseContext = createContext();

const HouseContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [allProperties, setAllProperties] = useState([]);
  const [allPropertiesOriginal, setAllPropertiesOriginal] = useState([]);
  const [propertyNeighborhoods, setPropertyNeighborhoods] = useState(
    "Todas as localizações"
  );
  const [uniqueNeighborhoods, setUniqueNeighborhoods] = useState([]);
  const [propertyType, setPropertyType] = useState("Todas as propriedades");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState("Todos os valores");

  useEffect(() => {
    const q = query(collection(dataBase, "properties"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const properties = snapshot.docs.map((doc) => ({
        id: doc.id,
        userId: doc.data().userId,
        propertyName: doc.data().propertyName,
        propertyType: doc.data().propertyType,
        propertyDescription: doc.data().propertyDescription,
        propertyNeighborhood: doc.data().propertyNeighborhood,
        propertyAdress: doc.data().propertyAdress,
        propertyLatitude: doc.data().propertyLatitude,
        propertyLongitude: doc.data().propertyLongitude,
        propertyBathrooms: doc.data().propertyBathrooms,
        propertySuites: doc.data().propertySuites,
        propertyBedrooms: doc.data().propertyBedrooms,
        propertySurface: doc.data().propertySurface,
        propertyPrice: doc.data().propertyPrice,
        agentName: doc.data().agentName,
        agentNumber: doc.data().agentNumber,
        mainPicture: doc.data().mainPicture,
        carouselPictures: doc.data().carouselPictures,
        agentPicture: doc.data().agentPicture,
      }));
      setAllProperties(properties);
      setAllPropertiesOriginal(properties);
      const allNeighborhoods = properties.map(
        (property) => property.propertyNeighborhood
      );
      const uniqueNeighborhoods = allNeighborhoods.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setUniqueNeighborhoods(["Todas as localizações", ...uniqueNeighborhoods]);

      const allPropertyTypes = properties.map(
        (property) => property.propertyType
      );
      const uniquePropertyType = allPropertyTypes.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      setPropertyTypes(["Todas as propriedades", ...uniquePropertyType]);
    });
    return () => unsubscribe();
  }, []);

  const handleClick = () => {
    setLoading(true);

    const isDefault = (str) => {
      return (
        str.split(" ").includes("Todas") || str.split(" ").includes("Todos")
      );
    };

    const minPrice = parseInt(propertyPrice.split(" ")[0]);
    const maxPrice = parseInt(propertyPrice.split(" ")[2]);

    const newHouses = allPropertiesOriginal.filter((property) => {
      const housePrice = parseInt(property.propertyPrice);

      if (
        property.propertyNeighborhood === propertyNeighborhoods &&
        property.propertyType === propertyType &&
        housePrice >= minPrice &&
        housePrice <= maxPrice
      ) {
        return property;
      }

      if (
        isDefault(propertyNeighborhoods) &&
        isDefault(propertyType) &&
        isDefault(propertyPrice)
      ) {
        return property;
      }

      if (
        !isDefault(propertyNeighborhoods) &&
        isDefault(propertyType) &&
        isDefault(propertyPrice)
      ) {
        return property.propertyNeighborhood === propertyNeighborhoods;
      }

      if (
        isDefault(propertyNeighborhoods) &&
        !isDefault(propertyType) &&
        isDefault(propertyPrice)
      ) {
        return property.propertyType === propertyType;
      }

      if (
        isDefault(propertyNeighborhoods) &&
        isDefault(propertyType) &&
        !isDefault(propertyPrice)
      ) {
        if (housePrice >= minPrice && housePrice <= maxPrice) {
          return property;
        }
      }

      if (
        !isDefault(propertyNeighborhoods) &&
        !isDefault(propertyType) &&
        isDefault(propertyPrice)
      ) {
        return (
          property.propertyNeighborhood === propertyNeighborhoods &&
          property.propertyType === propertyType
        );
      }

      if (
        !isDefault(propertyNeighborhoods) &&
        isDefault(propertyType) &&
        !isDefault(propertyPrice)
      ) {
        if (housePrice >= minPrice && housePrice <= maxPrice) {
          return property.propertyNeighborhood === propertyNeighborhoods;
        }
      }

      if (
        isDefault(propertyNeighborhoods) &&
        !isDefault(propertyType) &&
        !isDefault(propertyPrice)
      ) {
        if (housePrice >= minPrice && housePrice <= maxPrice) {
          return property.propertyType === propertyType;
        }
      }
    });

    setTimeout(() => {
      return (
        newHouses.length < 1
          ? setAllProperties([])
          : setAllProperties(newHouses),
        setLoading(false)
      );
    }, 1000);
  };

  return (
    <HouseContext.Provider
      value={{
        loading,
        handleClick,
        allProperties,
        setAllProperties,
        propertyTypes,
        propertyNeighborhoods,
        setPropertyNeighborhoods,
        uniqueNeighborhoods,
        setUniqueNeighborhoods,
        propertyType,
        setPropertyType,
        setPropertyTypes,
        propertyPrice,
        setPropertyPrice,
      }}
    >
      {children}
    </HouseContext.Provider>
  );
};

export default HouseContextProvider;
