import React from "react";

import { Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import PropertyDetails from "./pages/PropertyDetails";
import NotFound from "./components/NotFound";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import ManageProperties from "./pages/ManageProperties";
import { FaWhatsapp } from "react-icons/fa";

import PrivateRoute from "./PrivateRoute";
import EditHouse from "./pages/EditHouse";

const App = () => {
  return (
    <div className="relative bg-main">
      <div className="hidden xl:block absolute left-0 h-full w-12 bg-gradient-to-b from-[#98752D] via-[#E3C246] to-[#98752D] z-[1]"></div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/property/:id" element={<PropertyDetails />} />
        <Route path="/admin/edit/:id" element={<EditHouse />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              {" "}
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/newproperty"
          element={
            <PrivateRoute>
              {" "}
              <ManageProperties />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <a href="https://wa.me/558199906772" target="_blank">
        <div className="z-50 text-white text-3xl fixed bottom-4 right-4 bg-green-500 p-1 rounded-full">
          <FaWhatsapp />
        </div>
      </a>
      <Footer />
    </div>
  );
};

export default App;
